/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import * as PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import SpeakersRollHome from '../components/SpeakersRollHome'
import SpeakersRoll from '../components/SpeakersRoll'
import select from '../components/utils'
import ReactPlayer from 'react-player'
import SEO from '../components/SEO/SEO'
import { HTMLContent } from '../components/Content'
import ImagesGallery from '../components/ImagesGallery'
import BackgroundImage from 'gatsby-background-image'
import SponsorsGallery from '../components/SponsorsGallery'
import PartnersGallery from '../components/PartnersGallery'
import MediaRollHome from '../components/MediaRollHome'
import MediaRoll from '../components/MediaRoll'

const HomePageTemplate = ({ helmet, heroBGSources }) => {
    return (
        <>
            {helmet || ''}

            <BackgroundImage
                className="is-background-image"
                fluid={heroBGSources}
            >
                <section id="home" className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container"></div>
                    </div>
                </section>
            </BackgroundImage>

            <section id="about" className="hero is-medium has-background-light">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-multiline is-vcentered">
                            <div className="column is-12">
                                <h3 className="is-size-2 has-text-centered is-size-2-mobile has-text-weight-bold">
                                    About
                                </h3>
                            </div>
                            <div className="column is-6 content">
                                <div className="is-size-6 mt-4 is-size-6-mobile has-text-weight-bold">
                                    <p>
                                        Imagine having 100 top startup speakers
                                        at your fingertips;
                                    </p>
                                    <p>
                                        Startup leaders from 30+ countries to
                                        exchange the experience with;
                                    </p>
                                    <p>
                                        And a startup competition that involves
                                        Silicon Valley investors and a shot of a
                                        $1,000,000 global prize!
                                    </p>
                                </div>
                                <p className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                    Many of you attended SG Europe-Asia Connect
                                    last year and you remember the cool
                                    speakers, the great energy in the crowd, and
                                    maybe some useful connection you made.
                                </p>
                                <p className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                    This year’s online format opens the door to
                                    amazing speakers who don’t have time to fly
                                    to Georgia such as a VC with the first
                                    investment in Zoom and someone who helped
                                    grow the most valuable company in the world.
                                </p>
                                <p className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                    Get ready to learn from the great
                                    entrepreneurs in the crowd, perhaps get an
                                    enhanced networking ticket to make more
                                    valuable contacts. Or aim high and take a
                                    shot at the world stage in our Startup
                                    Showcase & Competition.
                                </p>
                                <p className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                    There has never been a better time for
                                    people from emerging markets to step onto
                                    the world stage. Take your shot at learning,
                                    connecting, and creating value!
                                </p>
                            </div>
                            <div className="column is-6">
                                <ReactPlayer
                                    className="react-player"
                                    width="100%"
                                    height="100%"
                                    url="https://www.youtube.com/watch?v=23RJupfb5NI"
                                    height={400}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="speakers"
                className="hero is-medium has-background-light"
            >
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered">
                            <div className="column is-12">
                                <h3 className="is-size-2 has-text-centered is-size-2-mobile has-text-weight-bold">
                                    Speakers
                                </h3>
                            </div>
                        </div>
                        <SpeakersRollHome />
                        {/* <SpeakersRoll /> */}
                    </div>
                </div>
            </section>

            {/*             
            <section
                id="competition"
                className="hero is-small has-background-light"
            >
                <div className="hero-body">
                    <div className="container content">
                        <div className="columns is-multiline">
                            <div className="column is-12 ">
                                <h3 className="is-size-2 has-text-centered is-size-2-mobile has-text-weight-bold">
                                    Competition
                                </h3>
                                <div className="column is-12 content">
                                    <p className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                        Startup Grind is the world’s largest
                                        community of startups, founders,
                                        innovators, and creators. Like-minded
                                        yet diverse individuals are brought
                                        together to connect, learn, teach, help,
                                        build, and belong. There are over 600
                                        chapters around the globe, collectively
                                        reaching more than 3.5 million
                                        individuals.
                                    </p>
                                    <p className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                        Last year, Georgia hosted Startup Grind
                                        Europe-Asia Connect, which was the 3rd
                                        largest Startup Grind conference
                                        globally, followed by Silicon Valley and
                                        London gatherings. We triumphantly
                                        performed a 2000 people event
                                        @RustaveliTheatre. Startups have
                                        successfully connected with investors to
                                        take their businesses to a higher level.
                                    </p>
                                    <p className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                        This year, due to the pandemic, the
                                        event will be 100% online. We are
                                        preparing to host about 5000
                                        participants, including founders of
                                        billion-dollar companies, investors,
                                        venture capitalists, startups, tech
                                        leaders, etc.
                                    </p>

                                    <ul className="is-size-6 mt-4 is-size-6-mobile has-text-weight-normal">
                                        <li>
                                            This will be the largest startup
                                            event in the Europe & Asia region
                                        </li>
                                        <li>
                                            We will host 100+ international
                                            speakers
                                        </li>
                                        <li>And the international audience</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section
                id="sponsors"
                className="hero is-medium has-background-light"
            >
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered is-multiline">
                            <div className="column is-12 ">
                                <h3 className="is-size-2 has-text-centered is-size-2-mobile has-text-weight-bold">
                                    Sponsors
                                </h3>
                                <SponsorsGallery />
                            </div>
                            <div className="column is-12 has-text-centered">
                                <a
                                    className="button is-primary is-outlined is-large has-text-weight-bold"
                                    href="https://share.hsforms.com/1Uvkt2WGLQLySVib_hi4x5A4u214"
                                    target="_blank"
                                >
                                    Become our Sponsor
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="media" className="hero is-medium has-background-light">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-vcentered is-multiline">
                            <div className="column is-12">
                                <h3 className="is-size-2 has-text-centered is-size-2-mobile has-text-weight-bold">
                                    Media
                                </h3>
                            </div>
                            <div className="column is-12">
                                <MediaRollHome />
                                <MediaRoll />
                            </div>
                            <div className="column is-12 mt-6">
                                <h3 className="is-size-4 has-text-left is-size-2-mobile has-text-weight-bold">
                                    Media Partners
                                </h3>
                            </div>
                            <div className="column is-12">
                                <PartnersGallery />
                            </div>
                            <div className="column is-12 has-text-centered">
                                <a
                                    className="button is-primary is-outlined is-large has-text-weight-bold"
                                    href="https://share.hsforms.com/1L_h-vMflQ66BpZwOIISBEQ4u214"
                                    target="_blank"
                                >
                                    Media Accreditation
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

HomePageTemplate.propTypes = {
    helmet: PropTypes.object,
}

class HomePage extends React.Component {
    render() {
        let data
        let dataMarkdown = []
        if (this.props.data !== null) {
            dataMarkdown = this.props.data.markdownRemark
            data = this.props.data
        }
        const jsonData = data.allArticlesJson.edges[0].node.articles
        const langKey = dataMarkdown.frontmatter.lang
        const { frontmatter } = data.markdownRemark
        const langFix = langKey === 'en' ? '' : 'ka'
        const sel = select(langKey)
        const heroBG = data.heroBG.childImageSharp.fluid
        const heroBGMobile = data.heroBGMobile.childImageSharp.fluid
        const heroBGSources = [
            heroBGMobile,
            {
                ...heroBG,
                media: `(min-width: 768px)`,
            },
        ]

        return (
            <Layout
                data={this.props.data}
                jsonData={jsonData}
                location={this.props.location}
            >
                <SEO frontmatter={frontmatter} />
                <div>
                    <HomePageTemplate
                        helmet={
                            <Helmet
                                titleTemplate={`${dataMarkdown.frontmatter.title}`}
                            >
                                <title>{`${dataMarkdown.frontmatter.title}`}</title>
                                <meta
                                    name="description"
                                    content={`${dataMarkdown.frontmatter.description}`}
                                />
                            </Helmet>
                        }
                        imageCardSL={dataMarkdown.frontmatter.imageCardSL}
                        title={dataMarkdown.frontmatter.title}
                        main={dataMarkdown.frontmatter.main}
                        contentComponent={HTMLContent}
                        content={dataMarkdown.html}
                        heroBG={heroBG}
                        heroBGMobile={heroBGMobile}
                        heroBGSources={heroBGSources}
                        langKey={langKey}
                        langFix={langFix}
                        sel={sel}
                    />
                </div>
            </Layout>
        )
    }
}

HomePage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
}

export default HomePage

export const pageQuery = graphql`
    query HomePageQuery($id: String!) {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        heroBG: file(relativePath: { eq: "hero-background.png" }) {
            id
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        heroBGMobile: file(relativePath: { eq: "hero-background-mobile.png" }) {
            id
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                id
                title
                description
                lang
            }
            fields {
                slug
            }
        }
    }
`
