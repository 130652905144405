import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

export default function SponsorsGallery() {
    return (
        <StaticQuery
            query={graphql`
                query SponsorsGalleryQuery {
                    adjarabet: file(relativePath: { eq: "adjarabet.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 240) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    bog: file(relativePath: { eq: "bog.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 300) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    gita: file(relativePath: { eq: "gita.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 300) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    gnta: file(relativePath: { eq: "gnta.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 200) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    hall: file(relativePath: { eq: "hall.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 80) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    nexiata: file(relativePath: { eq: "nexia-ta.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 180) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    silknet: file(relativePath: { eq: "silknet.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 200) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    teliani: file(relativePath: { eq: "teliani.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 180) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    usmac: file(relativePath: { eq: "us-mac.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 180) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    virbela: file(relativePath: { eq: "virbela.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 220) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    gec: file(relativePath: { eq: "gec.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 110) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    pegasus: file(relativePath: { eq: "pegasus.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 90) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    anova: file(relativePath: { eq: "anova.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 150) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <div className="mt-4">
                    <div className="columns is-multiline is-centered is-mobile is-vcentered">
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img fixed={data.gita.childImageSharp.fixed} />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img fixed={data.bog.childImageSharp.fixed} />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img
                                    fixed={data.adjarabet.childImageSharp.fixed}
                                />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img fixed={data.usmac.childImageSharp.fixed} />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img
                                    fixed={data.virbela.childImageSharp.fixed}
                                />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img
                                    fixed={data.pegasus.childImageSharp.fixed}
                                />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img fixed={data.gnta.childImageSharp.fixed} />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img fixed={data.gec.childImageSharp.fixed} />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img fixed={data.hall.childImageSharp.fixed} />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img
                                    fixed={data.nexiata.childImageSharp.fixed}
                                />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img
                                    fixed={data.teliani.childImageSharp.fixed}
                                />
                            </figure>
                        </div>
                        <div className="column is-narrow">
                            <figure className="image is-horizontal-centered is-flex">
                                <Img fixed={data.anova.childImageSharp.fixed} />
                            </figure>
                        </div>
                    </div>
                </div>
            )}
        />
    )
}
