import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import MdKeyboardArrowRight from '@meronex/icons/md/MdKeyboardArrowRight'
import Img from 'gatsby-image'

const switchData = (data, langKey) => {
    // eslint-disable-next-line no-unused-vars
    var posts: any
    switch (langKey) {
        case '':
            return (posts = data.en)
        case 'ka':
            return (posts = data.ka)
        default:
            return ' '
    }
}

const ModalContainer = (props) => {
    const [active, setActive] = useState(false)
    const toggle = () => setActive(!active)

    const { content } = props

    return (
        <div>
            <div className="column is-2">
                <a onClick={toggle}>
                    <MdKeyboardArrowRight
                        className="has-text-primary is-transition"
                        size="3rem"
                    />
                </a>
            </div>
            <ModalCard active={active} content={content} toggle={toggle} />
        </div>
    )
}

const ModalCard = ({ active, content, toggle }) => (
    <div className={`modal ${active ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={toggle} />
        <div className="modal-card">
            <section className="modal-card-body">{content}</section>
        </div>
        <button
            className="modal-close is-large"
            aria-label="close"
            onClick={toggle}
        ></button>
    </div>
)

class SpeakersRollHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = { url: '' }
    }

    getUrl() {
        const urlChecker =
            window.location.pathname !== '/speakers/'
                ? window.location.pathname
                : ''
        this.setState({ url: urlChecker })
    }

    componentDidMount() {
        this.getUrl()
    }

    render() {
        const { data } = this.props
        const langKey = this.state.url.slice(1, 3)
        const { edges: posts } = switchData(data, langKey)

        return (
            <div>
                <div
                    className="is-speakers-body is-collapsible is-active"
                    style={{ height: '100%' }}
                >
                    <div className="columns is-multiline">
                        {posts &&
                            posts.map(({ node: post }) => (
                                <div className="column is-4-desktop is-6-tablet">
                                    <div className="is-speakers-line has-background-primary"></div>
                                    <div className="columns is-mobile is-vcentered">
                                        <div className="column is-6">
                                            <Img
                                                className="is-rounded image is-speaker-image"
                                                alt={post.frontmatter.name}
                                                fluid={
                                                    post.frontmatter.image
                                                        .childImageSharp.fluid
                                                }
                                            />
                                        </div>
                                        <div className="column is-6">
                                            <h5 className="is-size-4 has-text-weight-semibold has-te">
                                                {post.frontmatter.name}
                                            </h5>
                                            <p className="has-text-weight-bold has-text-primary">
                                                {post.frontmatter.company}
                                            </p>
                                            <p className="is-size-6 has-text-weight-bold mt-2 mb-2">
                                                {post.frontmatter.session}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div className="is-speakers-line has-background-primary"></div> */}
                                    <div className="columns is-mobile">
                                        <div className="column is-10">
                                            <p>
                                                {
                                                    post.frontmatter
                                                        .short_description
                                                }
                                            </p>
                                        </div>
                                        <ModalContainer
                                            content={
                                                <div className="columns is-vcentered">
                                                    <div className="column is-4">
                                                        <Img
                                                            className="is-rounded image is-speaker-image"
                                                            alt={
                                                                post.frontmatter
                                                                    .name
                                                            }
                                                            fluid={
                                                                post.frontmatter
                                                                    .image
                                                                    .childImageSharp
                                                                    .fluid
                                                            }
                                                        />
                                                    </div>
                                                    <div className="column is-8">
                                                        <h5 className="is-size-4 has-text-weight-semibold">
                                                            {
                                                                post.frontmatter
                                                                    .name
                                                            }
                                                        </h5>

                                                        <p className="has-text-weight-bold has-text-primary">
                                                            {
                                                                post.frontmatter
                                                                    .company
                                                            }
                                                        </p>
                                                        <p className="is-size-6 has-text-weight-bold mt-2 mb-2">
                                                            {
                                                                post.frontmatter
                                                                    .session
                                                            }
                                                        </p>
                                                        <div className="is-speakers-line has-background-primary"></div>
                                                        <div>
                                                            <p>
                                                                {
                                                                    post
                                                                        .frontmatter
                                                                        .full_description
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        )
    }
}

SpeakersRollHome.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={graphql`
            query SpeakersRollHomeQuery {
                site {
                    siteMetadata {
                        title
                        languages {
                            langs
                            defaultLangKey
                        }
                    }
                }
                en: allMarkdownRemark(
                    limit: 82
                    sort: { order: ASC, fields: [frontmatter___number] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "speakers-post" }
                            lang: { regex: "/(en|any)/" }
                        }
                    }
                ) {
                    edges {
                        node {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                name
                                company
                                session
                                short_description
                                full_description
                                templateKey
                                lang
                                number
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 200, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ka: allMarkdownRemark(
                    limit: 9
                    sort: { order: ASC, fields: [frontmatter___number] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "speakers-post" }
                            lang: { regex: "/(ka|any)/" }
                        }
                    }
                ) {
                    edges {
                        node {
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                name
                                company
                                session
                                short_description
                                full_description
                                templateKey
                                lang
                                number
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 200, quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <SpeakersRollHome data={data} />}
    />
)
