import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
// import AliceCarousel from 'react-alice-carousel'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../fonts/layout.css'

export default function PartnersGallery() {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 6 },
    }

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // className: "slider-settings"
    }

    return (
        <StaticQuery
            query={graphql`
                query PartnersGalleryQuery {
                    marketer: file(relativePath: { eq: "1marketer.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    entrepreneur: file(
                        relativePath: { eq: "2entrepreneur.png" }
                    ) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    BMG: file(relativePath: { eq: "3BMG.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    formula: file(relativePath: { eq: "4formula.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    businessPartner: file(
                        relativePath: { eq: "5BusinessPartner.png" }
                    ) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    BPN: file(relativePath: { eq: "6BPN.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    CBW: file(relativePath: { eq: "cbw-logo.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    nextOnGe: file(
                        relativePath: { eq: "next-on-ge-logo.png" }
                    ) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                    emerging: file(relativePath: { eq: "emerging.png" }) {
                        id
                        childImageSharp {
                            fixed(quality: 100, width: 130) {
                                ...GatsbyImageSharpFixed_withWebp
                            }
                        }
                    }
                }
            `}
            render={(data) => (
                <div>
                    {/* <AliceCarousel
                        disableDotsControls="true"
                        autoPlay="true"
                        // autoPlayStrategy="action"
                        // mouseTracking
                        infinite="true"
                        responsive={responsive}
                        autoPlayInterval="2000"
                    >
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.marketer.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.entrepreneur.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.BMG.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.formula.childImageSharp.fixed}
                            />
                        </div>{' '}
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.BPN.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={
                                    data.businessPartner.childImageSharp.fixed
                                }
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.CBW.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.nextOnGe.childImageSharp.fixed}
                            />
                        </div>
                    </AliceCarousel> */}
                    <Slider {...sliderSettings}>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.marketer.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.entrepreneur.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.BMG.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.formula.childImageSharp.fixed}
                            />
                        </div>{' '}
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.BPN.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={
                                    data.businessPartner.childImageSharp.fixed
                                }
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.CBW.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.emerging.childImageSharp.fixed}
                            />
                        </div>
                        <div className="px-3 mx-6">
                            <Img
                                style={{ width: 130 }}
                                fixed={data.nextOnGe.childImageSharp.fixed}
                            />
                        </div>
                    </Slider>
                </div>
            )}
        />
    )
}
