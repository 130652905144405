import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Time from '../Time'

const switchData = (data, langKey) => {
    // eslint-disable-next-line no-unused-vars
    var posts: any
    switch (langKey) {
        case '':
            return (posts = data.en)
        case 'ka':
            return (posts = data.ka)
        default:
            return ' '
    }
}

class MediaRollHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            url: '',
        }
    }

    getUrl() {
        const urlChecker =
            window.location.pathname !== '/media/'
                ? window.location.pathname
                : ''
        this.setState({ url: urlChecker })
    }

    componentDidMount() {
        this.getUrl()
    }

    toObject() {
        const dateForm = this.posts.map(
            ({ node: post }) => post.frontmatter.date
        )
        var rv = {}
        for (var i = 0; i < dateForm.length; ++i) rv[i] = dateForm[i]
        return rv
    }

    render() {
        const { data } = this.props
        const langKey = this.state.url.slice(1, 3)
        const { edges: posts } = switchData(data, langKey)
        return (
            <div className="columns is-multiline my-3">
                {posts &&
                    posts.map(({ node: post }) => (
                        <div className="column is-4" key={post.id}>
                            <div className="container is-mobile is-transition">
                                <a href={post.frontmatter.url} target="_blank">
                                    <div className="card">
                                        <div className="card-image">
                                            <figure className="image">
                                                <Img
                                                    alt={post.frontmatter.title}
                                                    fluid={
                                                        post.frontmatter.image
                                                            .childImageSharp
                                                            .fluid
                                                    }
                                                />
                                            </figure>
                                        </div>
                                        <div className="card-content">
                                            <div className="media">
                                                <div className="media-content">
                                                    <p className="title is-5">
                                                        {post.frontmatter.title}
                                                    </p>
                                                    <Time
                                                        pubdate
                                                        langKey={
                                                            post.fields.langKey
                                                        }
                                                        date={
                                                            post.frontmatter
                                                                .date
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
            </div>
        )
    }
}

MediaRollHome.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}

// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={graphql`
            query MediaRollHomeQuery {
                site {
                    siteMetadata {
                        title
                        languages {
                            langs
                            defaultLangKey
                        }
                    }
                }
                en: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "media-post" }
                            lang: { regex: "/(en|any)/" }
                            number: { eq: "1" }
                        }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                date
                                number
                                url
                                lang
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ka: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    filter: {
                        frontmatter: {
                            templateKey: { eq: "media-post" }
                            lang: { regex: "/(ka|any)/" }
                            number: { eq: "1" }
                        }
                    }
                ) {
                    edges {
                        node {
                            excerpt(pruneLength: 150)
                            id
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                templateKey
                                date
                                number
                                url
                                lang
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => <MediaRollHome data={data} />}
    />
)
